import formMixin from '../../../mixins/formMixin'
import EventBus from '../../../utils/eventBus'

export default {
    name: 'FormSelectList',

    mixins: [formMixin],

    props: {
        markers: {
            type: Object
        }
    },

    data() {
        return {
            addItem: null
        }
    },

    computed: {
        isCheckin() {
            return this.form?.task == 'CustomerCheckin'
        },

        hasSelectedItems() {
            return this.field?.[this.model]?.length > 0
        },

        availableSelectListItems() {
            const selectedItems = this.field?.[this.model]
            return this.items?.filter(i => selectedItems?.indexOf(i.value) == -1)
        }
    },

    methods: {
        addItemToSelectList() {
            if (!this.addItem) {
                return
            }

            EventBus.$emit('addSelectListItem', {
                key: this.model,
                value: this.addItem,
                step: this.step
            })

            this.addItem = null
        },

        removeItemFromSelectList(item) {
            EventBus.$emit('removeSelectListItem', {
                key: this.model,
                value: item,
                step: this.step
            })
        },

        toggleItemOnSelectedList(item) {
            EventBus.$emit('toggleSelectedListItem', {
                key: 'customerGoodIntentions',
                value: item,
                step: this.step
            })
        },

        selectedItemName(item) {
            return this.items?.filter(i => i.value === item)?.[0]?.text
        }
    }
}
