const handoverItems = [
    {
        model: 'vehicleRegistration',
        label: 'ZLB 1 (Fahrzeugschein)'
    },
    {
        model: 'luggageCompartmentCover',
        label: 'Gepäckraumabdeckung'
    },
    {
        model: 'dividingNet',
        label: 'Trennnetz'
    },
    {
        model: 'logBook',
        label: 'Bordbuch'
    },
    {
        model: 'navigationSdCard',
        label: 'Navi SD Karte'
    },
    {
        model: 'navigationSystem',
        label: 'Navigationssystem'
    },
    {
        model: 'trailerHitch',
        label: 'Anhängerkupplung'
    },
    {
        model: 'firstAidKit',
        label: 'Verbandskasten'
    },
    {
        model: 'footMatsFront',
        label: 'Fußmatten vorne'
    },
    {
        model: 'footMatsRear',
        label: 'Fußmatten hinten'
    },
    {
        model: 'tirefit',
        label: 'TireFit'
    },
    {
        model: 'repairKit',
        label: 'Bordwerkzeug'
    },
    {
        model: 'sparetire',
        label: 'Reserverad'
    },
    {
        model: 'warningSign',
        label: 'Warndreieck'
    },
    {
        model: 'warningWest',
        label: 'Warnweste'
    },
    {
        model: 'tireRimCovers',
        label: 'Radnarbenabdeckung'
    },
    {
        model: 'heatingRemoteControl',
        label: 'Fernbedienung Standheizung'
    }
]

const electricCableItems = [
    {
        label: 'Schuko',
        model: 'cableSchuko',
        image: 'L2D_Stecker_SchuKo_150x150.png'
    },
    {
        label: 'Typ2',
        model: 'cableTyp2',
        image: 'L2D_Stecker_Typ_II_150x150.png'
    },
    {
        label: 'CCS',
        model: 'cableCCS',
        image: 'L2D_Stecker_CCS_150x150.png'
    }
]

const keysItems = [
    {
        text: 'Keine Auswahl',
        value: null
    }
]

const tiresItems = [
    {
        text: 'Keine Auswahl',
        value: null
    },
    {
        text: 'SR',
        value: 'summerTires'
    },
    {
        text: 'WR',
        value: 'winterTires'
    },
    {
        text: 'GJR',
        value: 'allSeasonTires'
    },
    {
        text: '8-fach',
        value: 'eightTires'
    }
]

const tankItems = [
    {
        text: 'Keine Auswahl',
        value: null
    },
    {
        text: '1/8',
        value: '1/8'
    },
    {
        text: '1/4',
        value: '1/4'
    },
    {
        text: '1/2',
        value: '1/2'
    },
    {
        text: '2/3',
        value: '2/3'
    },
    {
        text: '3/4',
        value: '3/4'
    },
    {
        text: 'voll',
        value: 'voll'
    }
]

const missingHandoverItems = [
    {
        text: 'Fahrzeugschlüssel',
        value: 'keys'
    },
    {
        text: 'Elektrokabel Schuko',
        value: 'cableSchuko'
    },
    {
        text: 'Elektrokabel CCS',
        value: 'cableCCS'
    },
    {
        text: 'Elektrokabel Typ2',
        value: 'cableTyp2'
    },
    {
        text: 'Bordbuch',
        value: 'logBook'
    },
    {
        text: 'Fahrzeugschein',
        value: 'vehicleRegistration'
    },
    {
        text: 'Trennnetz',
        value: 'dividingNet'
    },
    {
        text: 'Gepäckraumabdeckung',
        value: 'luggageCompartmentCover'
    },
    {
        text: 'Navi SD Karte',
        value: 'navigationSdCard'
    },
    {
        text: 'Navigationssystem',
        value: 'navigationSystem'
    },
    {
        text: 'Reifensatz SR',
        value: 'tiresSR'
    },
    {
        text: 'Reifensatz WR',
        value: 'tiresWR'
    },
    {
        text: 'Reifensatz GJR',
        value: 'tiresGJR'
    },
    {
        text: 'Fussmatten vorne',
        value: 'footMatsFront'
    },
    {
        text: 'Fussmatten hinten',
        value: 'footMatsRear'
    },
    {
        text: 'Tirefit',
        value: 'tirefit'
    },
    {
        text: 'Bordwerkzeug',
        value: 'repairKit'
    },
    {
        text: 'Reserverad',
        value: 'sparetire'
    },
    {
        text: 'Radnarbenabdeckung',
        value: 'tireRimCovers'
    },
    {
        text: 'Warndreieck',
        value: 'warningSign'
    },
    {
        text: 'Warnweste',
        value: 'warningWest'
    },
    {
        text: 'Anhängekupplung',
        value: 'trailerHitch'
    },
    {
        text: 'Verbandskasten',
        value: 'firstAidKit'
    },
    {
        text: 'Fernbedienung Standheizung',
        value: 'heatingRemoteControl'
    },
    {
        text: 'Radnarbenabdeckung',
        value: 'tireRimCovers'
    },
    {
        text: 'sonstiges (Infos im Textfeld „weitere fehlende Gegenstände“ eingeben)',
        value: 'others'
    }
]

export {
    handoverItems,
    tiresItems,
    electricCableItems,
    keysItems,
    tankItems,
    missingHandoverItems
}