import { getBringMissingPartsDate } from '../../../utils/date-utils'
import stepMixin from '../../../mixins/stepMixin'
import FormCheck from '../../form/formCheck/FormCheck.vue'
import FormTextarea from '../../form/formTextarea/FormTextarea.vue'
import FormInput from '../../form/formInput/FormInput.vue'
import FormSelect from '../../form/formSelect/FormSelect.vue'
import FormSelectList from '../../form/formSelectList/FormSelectList.vue'
import { handoverItems,
    tiresItems,
    electricCableItems,
    keysItems,
    tankItems,
    missingHandoverItems } from '../../../constants/equipmentItems'

export default {
    name: 'HandoverProtocol',

    props: {
        stepIsValid: {
            type: Boolean,
            required: true
        }
    },

    mixins: [stepMixin],

    components: {
        FormCheck,
        FormTextarea,
        FormInput,
        FormSelect,
        FormSelectList
    },

    data() {
        return {
            handoverItems,
            electricCableItems,
            keysItems,
            tiresItems,
            tankItems,
            missingHandoverItems
        }
    },

    created() {
        // build key items for selection
        for (let i = 0; i <= 10; i++) {
            this.keysItems.push({
                text: i,
                value: i
            })
        }

        this.setInitialMissingItems()
    },

    computed: {
        hasMissingItems() {
            return (this.field?.['missingItems']?.length > 0) || (this.field?.['missingItemsText']?.length > 0)
        },

        hasMissingItemsOther() {
            return (this.field?.['missingItems']?.filter(i => i == 'others').length > 0)
        },

        hasMissingItemsBringCustomer() {
            return this.field?.['missingItemsBringCustomer']
        },
    },

    methods: {
        validate() {
            // No validation here
            let isValid = true

            if (this.field.keys === null || !this.field.tires) {
                isValid = false
            }

            if (
                (this.task == 'CustomerCheckin') &&
                this.hasMissingItems &&
                !this.hasMissingItemsBringCustomer
            ) {
                isValid = false
            }

            this.$emit('update:stepIsValid', isValid)
        },

        isCheckoutEquipmentItem(item) {
            if (this.task !== 'CustomerCheckin') {
                return false
            }
            return this.form?.vehicle?.currentEquipment?.[item.model] === true
        },

        setCheckinEquipmentItem(item) {
            if ( this.field?.['missingItems']?.includes(item.model)) {
                this.field?.['missingItems']?.splice(this.field?.['missingItems']?.indexOf(item.model),1)
            }
        },

        toggleCheckinEquipmentItem(item) {
            if (this.view === 'summary') {
                return
            }
            if ( this.field?.[item.model] !== undefined ) {
                this.field[item.model] = !this.field?.[item.model]
            }
        },

        setInitialMissingItems() {
            if (this.view === 'summary' || this.task !== 'CustomerCheckin') {
                return
            }
            if (this.form?.vehicle.currentAdditionalItems) {
                if (this.field?.['missingItems']?.length == 0) {
                    Object
                        .keys(this.form?.vehicle?.currentEquipment)
                        ?.filter((k) => { return this.form?.vehicle?.currentEquipment?.[k] === true })
                        ?.map((k) => { this.field?.['missingItems'].push(k); return k })
                }
            }

            if (this.form?.vehicle.currentAdditionalItems?.length > 0) {
                if (this.field?.['missingItemsText']?.length == 0) {
                    this.field['missingItemsText'] = this.form?.vehicle?.currentAdditionalItems
                    if (this.form?.vehicle.currentAdditionalItemsMissing?.length > 0) {
                        this.field['missingItemsText'] += ', ' + this.form?.vehicle?.currentAdditionalItemsMissing
                    }
                    this.field?.['missingItems'].push('others')
                }
            }
        },

        getBringMissingPartsDate() {
            if (!this.field['missingItemsBringUntil']) {
                this.setBringMissingPartsDate()
            }
            return this.field['missingItemsBringUntil']
        },

        setBringMissingPartsDate() {
            this.field['missingItemsBringUntil'] = null
            if (this.hasMissingItems) {
                this.field['missingItemsBringUntil'] = getBringMissingPartsDate()
            }
        }
    },

    watch: {
        'field' : {
            handler(selectedEquipment){
                Object
                    .keys(selectedEquipment)
                    ?.filter((k) => { return selectedEquipment?.[k] === true })
                    ?.map((k) => { this.setCheckinEquipmentItem({ model: k }); return k })
            },
            deep: true
        },
    }
}
